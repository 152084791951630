import React, {
  useReducer,
  useEffect,
  useMemo,
  MouseEvent,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import TableContainer, {
  Title as ContainerTitle,
} from "@components/TableContainer";
import {
  DEFAULT_CURRENCIES_ARRAY,
  DEFAULT_INTERVALS_ARRAY,
  DEFAULT_INTERVALS_ANOTHER_FORMAT_ARRAY,
} from "@lib/const";
import useSWR from "swr";
import Breadcrumbs, { BreadcrumbsProps } from "@components/Breadcrumbs";
import { TitleOptions } from "@components/title-options";
import fetcher from "@lib/fetcher";
import { getPageLayout } from "@components/app";
import { Interval, Currency } from "@lib/types";
import NextLink from "next/link";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import LazyRender from "@components/LazyRender";
import { TrendDataType } from "./types";
import querystring from "querystring";
import { SortItem } from "@lib/types";
import LatestNews from "@components/LatestNews";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";
import cx from "classnames";
import BtnBgLeftDark from "@public/view-all-btn-bg-left-dark.svg";
import BtnBgRightDark from "@public/view-all-btn-bg-right-dark.svg";
import BtnBgLeftLight from "@public/view-all-btn-bg-left-light.svg";
import BtnBgRightLight from "@public/view-all-btn-bg-right-light.svg";
import StickyHeaderWrapper from "@components/StickyHeaderWrapper";
import Picker from "@components/Picker";
import { Price, PriceChangeMsg } from "@lib/mqtt/helper-types";
import trackEvent, {
  GAEventAction,
  GAEventCategory,
  GAEventLabel,
} from "@lib/ga";

const DynamicTopTrends = dynamic(() => import("@components/TopTrends"), {
  loading: () => (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <CircularProgress color="primary" />
    </Box>
  ),
});

export type GetTitles<TSortColumns extends string> = ({
  currency,
  interval,
  activeCategory,
}: {
  currency: Currency["slug"];
  interval?: Interval["slug"];
  activeCategory?: number;
}) => ContainerTitle<TSortColumns>[];

export type GetSortBy<TSortColumns extends string> = ({
  currency,
  interval,
  activeCategory,
}: {
  currency: Currency["slug"];
  interval?: Interval["slug"];
  activeCategory?: number;
}) => TSortColumns;

export type Title<TSortColumns extends string = string> =
  | string
  | {
      title: string;
      sortBy?: TSortColumns | GetSortBy<TSortColumns>;
      props?: {
        align?: "inherit" | "left" | "center" | "right" | "justify";
        width?: number | string;
      };
      styles?: {
        padding?: number | string;
      };
      filter?: string;
      intervalIcon?: string;
      sortAfterFiveSeconds?: boolean;
    };

export type assetCoinsData = {
  coin_symbol: string;
  coin_title: string;
};

export interface StaticPageProps<TRow = unknown> {
  initialData: TRow[];
  dataCount?: number;
  initialTrends?: TrendDataType[];
  pagesCount?: number;
  pageSize?: number;
  assetCoins?: assetCoinsData[];
}

interface PageStateProps {
  currency: Currency;
  interval?: Interval;
}

interface RowStateProps extends PageStateProps {
  index: number;
  isExpanded?: boolean;
}

interface TablePageProps<TRow, TSortColumns extends string>
  extends StaticPageProps<TRow> {
  trendsFirstIndex?: number;
  pageTitle: string;
  pageSubtitle?: string;
  api: string;
  getTitles: GetTitles<TSortColumns>;
  rowKey: string;
  rowContent: (row: TRow, data: RowStateProps) => React.ReactNode;
  mobileItem: (row: TRow, data: RowStateProps) => React.ReactNode;
  useIntervals?: boolean;
  header?: (data: PageStateProps) => React.ReactNode;
  pages?: BreadcrumbsProps["data"];
  pageSize?: number;
  onNewData?: (data: TRow[]) => void;
  onIntervalChange?: (interval: Interval) => void;
  childrensAboveTable?: (currency: Currency) => React.ReactNode;
  serverSideSorting?: boolean;
  isFixedLayout?: boolean;
  width?: string;
  useIntervalAnotherFormat?: boolean;
  firstSort?: (
    interval: Interval,
    currency: Currency,
  ) => SortItem<TSortColumns>;
  homePage?: boolean;
  mobileBreakpoint?: "sm" | "md" | "lg" | "xl";
  titleOptionsTitleContent?: React.ReactNode;
  tableContainerClassName?: string;
  onViewAllBtnClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  showStickyHeader?: boolean;
  stickyHeaderContent?: (currency: Currency) => React.ReactNode;
  titleTextContent?: (currency: Currency) => React.ReactNode;
  topHeader?: number;
  boxClassName?: string;
  subChildrensAboveTable?: (currency?: Currency) => React.ReactNode;
  filterBy?: string;
  prices?: Map<string, Price>;
  pricesChange?: Map<string, PriceChangeMsg>;
  gaEventCategory?: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
  viewAllButton: {
    width: "100%",
    background: theme.palette.viewAllButton.background,
    boxShadow: theme.palette.viewAllButton.shadow,
    borderRadius: 8,
    textTransform: "capitalize",
    padding: "16px 12px",
    position: "relative",
    overflow: "hidden",
    p: {
      color: theme.palette.viewAllButton.text,
    },
  },
  viewAllButtonBgLeft: {
    position: "absolute",
    top: 0,
    [theme.breakpoints.down("sm")]: {
      right: "50%",
    },
    [theme.breakpoints.up("sm")]: {
      left: 0,
    },
  },
  viewAllButtonBgRight: {
    position: "absolute",
    top: 0,
    [theme.breakpoints.down("sm")]: {
      left: "50%",
    },
    [theme.breakpoints.up("sm")]: {
      right: 0,
    },
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    zIndex: 2,
    [theme.breakpoints.only("xs")]: {
      margin: "0 -4px",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
    },
  },
  margin: {
    marginRight: 0,

    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
    [theme.breakpoints.only("md")]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(3),
    },
  },
  stickyHeader: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    borderBottom: "1px solid rgba(255, 255, 255, 0.15)",
    zIndex: 100,
    transform: "translateY(-100%)",
    transition: "transform .2s ease-in",
    "&._visible": {
      transform: "translateY(0%)",
    },
  },
  optionBox: {
    display: "flex",
    marginLeft: 0,
    zIndex: 2,

    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      "> *": {
        width: "48%",
      },
    },

    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      marginLeft: theme.spacing(5),
    },
    [theme.breakpoints.only("md")]: {
      alignItems: "flex-end",
      flexDirection: "column",
    },
    "& .pickerBox": {
      padding: 0,
    },
  },
}));

export default function TablePage<
  TRow = unknown,
  TSortColumns extends string = string
>({
  initialData,
  initialTrends,
  trendsFirstIndex,
  getTitles,
  dataCount,
  pageTitle,
  api,
  rowContent,
  rowKey,
  useIntervals,
  header,
  pages,
  pageSize,
  onNewData,
  onIntervalChange,
  childrensAboveTable,
  serverSideSorting = true,
  isFixedLayout,
  width,
  useIntervalAnotherFormat,
  firstSort,
  pageSubtitle,
  homePage = false,
  mobileItem,
  mobileBreakpoint,
  titleOptionsTitleContent,
  tableContainerClassName,
  onViewAllBtnClick,
  showStickyHeader = false,
  stickyHeaderContent,
  topHeader,
  titleTextContent,
  boxClassName,
  subChildrensAboveTable,
  filterBy,
  prices,
  pricesChange,
  gaEventCategory,
}: TablePageProps<TRow, TSortColumns>) {
  const theme: ITheme = useTheme();
  const router = useRouter();
  const [state, dispatch] = useReducer<
    React.Reducer<State<TRow, TSortColumns>, Action<TRow, TSortColumns>>
  >(reducer, {
    currency: DEFAULT_CURRENCIES_ARRAY[0],
    interval: useIntervalAnotherFormat
      ? DEFAULT_INTERVALS_ANOTHER_FORMAT_ARRAY[0]
      : useIntervals
      ? DEFAULT_INTERVALS_ARRAY[0]
      : undefined,
    loading: false,
    error: void 0,
    result: initialData,
    sortBy: firstSort
      ? firstSort(DEFAULT_INTERVALS_ARRAY[0], DEFAULT_CURRENCIES_ARRAY[0])
      : undefined,
    viewAllButton: true,
  });
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [scrollPosElem, setScrollPosElem] = useState<number | undefined>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const pageNumber = useMemo(() => {
    if (router.query.page) {
      return router.query.page === "0" || router.query.page === "1"
        ? 0
        : parseInt(router.query.page as string) - 1;
    }
    return 0;
  }, [router.query.page]);

  useEffect(() => {
    if (api && (!initialData || initialData.length === 0)) {
      dispatch({ type: "SET_LOADING_STATUS", value: true });
    }
  }, []);

  useSWR<TRow[]>(
    () => {
      const sortQuery =
        typeof state.sortBy !== "undefined"
          ? querystring.stringify({
              sortColumn: state.sortBy.column,
              sortOrder: state.sortBy.order,
            })
          : "";

      const apiQuery = api
        ? dataCount
          ? `${api}?page=${serverSideSorting ? pageNumber : -1}${
              !!filterBy ? `&filterBy=${filterBy}&` : "&"
            }${serverSideSorting ? sortQuery : ""}`
          : `${api}?page=-1${!!filterBy ? `&filterBy=${filterBy}` : "&"}${
              serverSideSorting ? sortQuery : ""
            }`
        : null;

      return apiQuery;
    },
    fetcher,
    {
      onSuccess: (data) => {
        onNewData && onNewData(data);

        dispatch({
          type: "SET_RESULT",
          result: data,
        });
      },
      onError: (err) => {
        dispatch({
          type: "SET_ERROR",
          error: err,
        });
      },
    },
  );

  const titles = useMemo(() => {
    return getTitles({
      currency: state.currency.slug,
      interval: state.interval?.slug,
    });
  }, [getTitles, state.currency.slug, state.interval?.slug]);

  const handleScroll = () => {
    if (ref.current) {
      setScrollPosElem(ref.current.getBoundingClientRect().top);
    }
  };

  useEffect(() => {
    if (showStickyHeader) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (showStickyHeader) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  });

  const newData = useMemo(() => {
    if (prices && pricesChange) {
      return state?.result?.map((item) => ({
        ...item,
        price:
          prices.has(item[`luner_coin_symbol`]) &&
          prices.get(item[`luner_coin_symbol`]).usd,
        price_change:
          pricesChange.has(item[`luner_coin_symbol`]) &&
          pricesChange.get(item[`luner_coin_symbol`]).usd,
      }));
    }
    return state.result;
  }, [state.result, prices, pricesChange]);

  return (
    <div className={boxClassName}>
      {pages && (
        <Box mb={4.5}>
          <Breadcrumbs data={pages} />
        </Box>
      )}
      {header &&
        header({
          interval: useIntervals ? state.interval : undefined,
          currency: state.currency,
        })}
      {showStickyHeader && (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={cx(classes.stickyHeader, {
              _visible: scrollPosElem < -70,
            })}>
            <StickyHeaderWrapper>
              <Box className={classes.card}>
                {stickyHeaderContent && stickyHeaderContent(state.currency)}
                <Grid item xs={12} md={4} className={classes.optionBox}>
                  {useIntervals ? (
                    <Box className={`${classes.margin} picker`}>
                      <Picker
                        value={state.interval}
                        values={DEFAULT_INTERVALS_ARRAY}
                        setValue={(interval) => {
                          if (typeof onIntervalChange !== "undefined") {
                            onIntervalChange(interval);
                          }

                          dispatch({
                            type: "SET_INTERVAL",
                            interval,
                          });
                        }}
                        gaEventCategory={GAEventCategory.StickyHeader}
                      />
                    </Box>
                  ) : null}
                  <Box>
                    <Picker
                      value={state.currency}
                      values={DEFAULT_CURRENCIES_ARRAY}
                      setValue={(currency) => {
                        dispatch({
                          type: "SET_CURRENCY",
                          currency,
                        });
                      }}
                      withoutText
                      gaEventCategory={GAEventCategory.StickyHeader}
                    />
                  </Box>
                </Grid>
              </Box>
            </StickyHeaderWrapper>
          </Box>
          <div ref={ref} />
        </>
      )}
      <TitleOptions
        titleText={pageTitle}
        subtitleText={pageSubtitle}
        useIntervals={useIntervals}
        selectedCurrency={state.currency}
        selectedInterval={state.interval}
        setCurrency={(currency) => {
          dispatch({
            type: "SET_CURRENCY",
            currency,
          });
        }}
        setInterval={(interval) => {
          if (typeof onIntervalChange !== "undefined") {
            onIntervalChange(interval);
          }

          dispatch({
            type: "SET_INTERVAL",
            interval,
          });
        }}
        titleContent={titleOptionsTitleContent}
        titleTextContent={titleTextContent}
        gaEventCategory={gaEventCategory}
      />
      {subChildrensAboveTable && subChildrensAboveTable(state.currency)}
      {childrensAboveTable && childrensAboveTable(state.currency)}
      <TableContainer<TRow, TSortColumns>
        data={newData}
        dataCount={dataCount}
        loading={state.loading}
        page={
          state.viewAllButton && homePage
            ? undefined
            : dataCount
            ? pageNumber
            : undefined
        }
        pageSize={pageSize}
        showMoreItem={!homePage}
        isFixedLayout={isFixedLayout}
        width={width}
        onChangePage={() => {
          dispatch({ type: "SET_RESULT", value: [] });
          dispatch({ type: "SET_LOADING_STATUS", value: true });
          // dispatch({
          //   type: "SET_PAGE",
          //   value: page,
          // })
        }}
        useRouterPagination
        rowKey={rowKey}
        // sortData={{
        //   currency: state.currency.slug,
        //   interval: useIntervals ? state.interval.slug : undefined,
        // }}
        onChangeSorting={(sortBy) => {
          if (serverSideSorting === true) {
            dispatch({ type: "SET_RESULT", result: [] });
            dispatch({ type: "SET_LOADING_STATUS", loading: true });
            dispatch({ type: "SET_SORT_BY", sortBy });
          }
        }}
        titles={titles}
        rowContent={(data, index) =>
          rowContent(data, {
            index,
            interval: useIntervals ? state.interval : undefined,
            currency: state.currency,
          })
        }
        mobileItem={(data, index, isExpanded) =>
          mobileItem(data, {
            index,
            interval: useIntervals ? state.interval : undefined,
            currency: state.currency,
            isExpanded,
          })
        }
        mobileBreakpoint={mobileBreakpoint}
        className={tableContainerClassName}
        topHeader={topHeader}
        gaEventCategory={gaEventCategory}
        // homePage={homePage}
      />
      {/* {initialTrends && (
        <LazyRender>
          <DynamicTopTrends
            currency={state.currency}
            initialTrends={initialTrends}
            firstIndex={trendsFirstIndex}
          />
        </LazyRender>
      )} */}
      {state.viewAllButton && homePage && (
        <Button
          onClick={(e) => {
            if (typeof onViewAllBtnClick !== "undefined") {
              onViewAllBtnClick(e);
            } else {
              dispatch({ type: "SET_VIEW_ALL_BUTTON_STATUS", status: false });
            }
            trackEvent(
              {
                action: GAEventAction.ViewAllAssets,
                category: GAEventCategory.HomePage,
                label: GAEventLabel.ViewAllAssets,
              },
              isMobile,
            );
          }}
          className={cx("view-all-btn", classes.viewAllButton)}>
          <Box className={classes.viewAllButtonBgLeft}>
            <BtnBg left mode={theme.palette.mode} />
          </Box>
          <Typography variant="body1">View All</Typography>
          <Box className={classes.viewAllButtonBgRight}>
            <BtnBg mode={theme.palette.mode} />
          </Box>
        </Button>
      )}

      {homePage && process.env.NEXT_PUBLIC_NEWS_DISABLED !== "1" && (
        <LatestNews />
      )}
    </div>
  );
}

function BtnBg({ left = false, mode }) {
  switch (mode) {
    case "dark":
      return left ? <BtnBgLeftDark /> : <BtnBgRightDark />;
    case "light":
      return left ? <BtnBgLeftLight /> : <BtnBgRightLight />;
    default:
      return null;
  }
}

// type Reducer<TRow> = (state: State<TRow>, action: Action<TRow>) => State<TRow>;

function reducer(state, action) {
  switch (action.type) {
    case "SET_RESULT":
      return {
        ...state,
        loading: false,
        result: action.result,
        error: void 0,
      };
    case "SET_ERROR":
      return {
        ...state,
        loading: false,
        result: void 0,
        error: action.error,
      };
    case "SET_CURRENCY":
      return { ...state, currency: action.currency };
    case "SET_INTERVAL":
      return { ...state, interval: action.interval };
    case "SET_SORT_BY":
      return { ...state, sortBy: action.sortBy };
    case "SET_LOADING_STATUS":
      return { ...state, loading: action.loading };
    case "SET_VIEW_ALL_BUTTON_STATUS":
      return { ...state, viewAllButton: action.status };
  }
}

interface State<TRow, TSortColumns extends string> {
  currency: Currency;
  interval: Interval;
  loading: boolean;
  error: Error;
  result: TRow[];
  sortBy?: SortItem<TSortColumns>;
  viewAllButton?: boolean;
}

interface Action<TRow, TSortColumns extends string> {
  type: string;
  currency?: Currency;
  interval?: Interval;
  loading?: boolean;
  sortBy?: SortItem<TSortColumns>;
  error?: Error;
  result?: TRow[];
  page?: number;
  value?: boolean | unknown[];
  status?: boolean;
}

export function getLayout(Page, pageProps) {
  return getPageLayout(Page, pageProps, {
    largeMargin: pageProps.pages === undefined,
  });
}

function PageLink(props) {
  return (
    <NextLink href={props.href} shallow={true} passHref={true}>
      <a {...props} />
    </NextLink>
  );
}

export function getDefaultGetTitles<TSortColumns extends string = string>(
  titles: Title[],
  activeCategory?: number,
): GetTitles<TSortColumns> {
  titles = titles.slice();

  return ({ currency, interval }): ContainerTitle<TSortColumns>[] => {
    return titles.map((v) => {
      if (typeof v === "object" && typeof v.sortBy === "function") {
        v.sortBy = v.sortBy({
          currency: currency,
          interval: interval,
          activeCategory: activeCategory,
        });
      }

      return v as ContainerTitle<TSortColumns>;
    });
  };
}

export const columnsToHide = (titles: Title[], hideColumns?: string[] | []) => {
  if (hideColumns.length > 0) {
    return titles.filter((v) => {
      if (typeof v === "object") {
        return !hideColumns.some((column) => column === v.title);
      }

      return true;
    });
  }
  return titles;
};

export function extractSortColumns(titles: Title[]): string[] {
  return Array.from(
    titles.reduce((accum, v) => {
      if (typeof v === "object") {
        if (typeof v.sortBy === "string") {
          accum.add(v.sortBy);
        } else if (typeof v.sortBy === "function") {
          for (const { slug: currency } of DEFAULT_CURRENCIES_ARRAY) {
            for (const { slug: interval } of DEFAULT_INTERVALS_ARRAY) {
              for (let i = 0; i <= 4; i++) {
                const column = v.sortBy({
                  currency,
                  interval,
                  activeCategory: i,
                });
                accum.add(column);
              }
            }
          }
        }
      }

      return accum;
    }, new Set<string>()),
  );
}
