import React, { useEffect, useState } from "react";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";
import {
  Box,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CalendarIcon from "@public/calendar.svg";

interface LatestNewsProps {
  byCoin?: {
    coin_symbol: string;
    coin_title: string;
  };
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    marginTop: 80,
  },
  content: {
    marginTop: 40,
    height: 620,
  },
  leftContent: {
    height: "100%",
    width: "50%",
    position: "relative",
  },
  rightContent: {
    height: "100%",
    width: "50%",
  },
  rightContentItem: {
    height: "47%",
    marginLeft: 40,
    width: "calc(48% - 40px)",
    position: "relative",
  },
  mainCardImg: {
    "& img": {
      width: "100%",
      height: "100%",
      borderRadius: "16px",
      objectFit: "cover",
    },
    opacity: 0.8,

    "&:hover": {
      opacity: 0.6,
    },
  },
  mainCardContent: {
    position: "absolute",
    bottom: 40,
    left: 0,
    padding: "0px 40px",
  },
  mainTitle: {
    marginBottom: 8,
    "& a": {
      fontSize: 24,
      lineHeight: "32px",
      color: theme.palette.secondary.main,
      textDecoration: "none",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 16,
    },
  },
  mainSubtitle: {
    fontSize: 16,
    lineHeight: "24px",
    marginBottom: 26,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
  },
  description: {
    fontSize: 16,
    lineHeight: "24px",
    color: theme.palette.secondary.main,
  },
  sentiment: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 600,
  },
  cardImg: {
    "& img": {
      width: "100%",
      height: "60%",
      borderRadius: "8px",
    },
    opacity: 0.8,
    "&:hover": {
      opacity: 0.6,
    },
  },
  title: {
    // marginTop: 14,
    "& a": {
      fontSize: 16,
      lineHeight: "24px",
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
  },
  desc: {
    fontSize: 16,
    lineHeight: "24px",
    color: "#9BA2BA",
    marginTop: 18,
    position: "absolute",
    bottom: 0,
    left: 0,
    "& path": {
      fill: theme.palette.text.secondary,
    },
    "& svg": {
      marginRight: 8,
    },
  },
  mainNews: {
    // backgroundColor: "#043241",
    // border: "1px solid #1A5A6F",
    // boxShadow: "0px 8px 32px rgba(255, 255, 255, 0.04)",
    width: "100%",
    marginBottom: 24,
    borderRadius: 16,
    height: 328,
    position: "relative",
  },
  contentMob: {
    padding: 24,
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  mainCardImgMob: {
    "& img": {
      width: "100%",
      minHeight: "100%",
      borderRadius: "16px 16px 0px 0px",
      objectFit: "cover",
    },
  },
  date: {
    color: "#9BA2BA",
    fontSize: 16,
    lineHeight: "24px",
    marginTop: theme.spacing(1),
  },
  abotherNews: {
    // backgroundColor: "#043241",
    // border: "1px solid #1A5A6F",
    // boxShadow: "0px 8px 32px rgba(255, 255, 255, 0.04)",
    // borderRadius: 16,
  },
  newsMob: {
    marginBottom: theme.spacing(4),
    // borderBottom: "1px solid #113F60",
    // padding: 24,
    // marginBottom: theme.spacing(4),
    // "&:last-child": {
    //   borderBottom: "none",
    //   marginBottom: 0,
    // },
  },
  cardImgMob: {
    marginRight: theme.spacing(3),
    "& img": {
      width: 80,
      height: 80,
      borderRadius: 8,
    },
  },
  calendarIcon: {},
}));

const LatestNews = ({ byCoin }: LatestNewsProps) => {
  const theme: ITheme = useTheme();
  const downMdMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [news, setNews] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (byCoin) {
      fetch(`/api/news/get-news?tickers=${byCoin.coin_symbol}`)
        .then((response) => response.json())
        .then((data) => setNews(data));
    } else {
      fetch(`/api/news/get-general-news?items=5`)
        .then((response) => response.json())
        .then(({ data }) => setNews(data));
    }
  }, [byCoin?.coin_symbol]);

  if (!news || !news.length) {
    return null;
  }

  return (
    <Box className={classes.root}>
      {news && (
        <>
          <Typography variant="h2">{`Latest News ${
            byCoin ? `about ${byCoin.coin_title}` : ""
          }`}</Typography>
          {news.length > 0 && (
            <>
              {!downMdMatch ? (
                <Box display="flex" className={classes.content}>
                  <Box className={classes.leftContent}>
                    <Link
                      className={classes.mainCardImg}
                      href={news[0].news_url}
                      rel="nofollow noopener noreferrer"
                      target="_blank">
                      <img src={news[0].image_url} />
                    </Link>
                    <Box className={classes.mainCardContent}>
                      <Box className={classes.mainTitle}>
                        <Link
                          href={news[0].news_url}
                          rel="nofollow noopener noreferrer"
                          target="_blank">
                          {news[0].title}
                        </Link>
                      </Box>
                      <Typography className={classes.mainSubtitle}>
                        {news[0].text}
                      </Typography>
                      <Box>
                        <Typography className={classes.description}>
                          {`${news[0].source_name} | ${news[0].date
                            .split(" ")
                            .slice(0, -2)
                            .join(" ")}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="column"
                    justifyContent="space-between"
                    className={classes.rightContent}>
                    {[...news]?.slice(1, 5)?.map((news) => (
                      <Box className={classes.rightContentItem}>
                        <Link
                          className={classes.cardImg}
                          href={news.news_url}
                          rel="nofollow noopener noreferrer"
                          target="_blank">
                          <img src={news.image_url} />
                        </Link>
                        <Box className={classes.title}>
                          <Link
                            href={news.news_url}
                            rel="nofollow noopener noreferrer"
                            target="_blank">
                            {news.title}
                          </Link>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          className={classes.desc}>
                          <CalendarIcon />
                          <Typography variant="body2">
                            {news.date.split(" ").slice(1, -2).join(" ")}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box mt="20px" display="flex" flexDirection="column">
                  <Box className={classes.mainNews}>
                    <Link
                      className={classes.mainCardImgMob}
                      href={news[0].news_url}
                      rel="nofollow noopener noreferrer"
                      target="_blank">
                      <img src={news[0].image_url} />
                    </Link>
                    <Box className={classes.contentMob}>
                      <Box className={classes.mainTitle}>
                        <Link
                          href={news[0].news_url}
                          rel="nofollow noopener noreferrer"
                          target="_blank">
                          {news[0].title}
                        </Link>
                      </Box>
                      {/* <Typography className={classes.mainSubtitle}>
                    {news[0].text}
                  </Typography> */}
                      <Typography className={classes.date}>
                        {news[0].date.split(" ").slice(0, -2).join(" ")}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.abotherNews}>
                    {[...news]?.slice(1, 5)?.map((news) => {
                      const date = news.date.split(" ");
                      const newDate = `${date[1]} ${date[2]} ${date[3]}`;
                      return (
                        <Box display="flex" className={classes.newsMob}>
                          <Link
                            className={classes.cardImgMob}
                            href={news.news_url}
                            rel="nofollow noopener noreferrer"
                            target="_blank">
                            <img src={news.image_url} />
                          </Link>
                          <Box>
                            <Box className={classes.title}>
                              <Link
                                href={news.news_url}
                                rel="nofollow noopener noreferrer"
                                target="_blank">
                                {news.title}
                              </Link>
                            </Box>
                            <Typography
                              variant="body2"
                              className={classes.date}>
                              {newDate}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default LatestNews;
