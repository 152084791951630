import { useState } from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  ButtonBase,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";
import BitcoinIcon from "@public/bitcoin-icon.svg";
import EthereumIcon from "@public/ethereum-icon.svg";
import DollarIcon from "@public/dollar-icon.svg";
import ExpandMore from "@public/expand-more.svg";
import cx from "classnames";
import trackEvent from "@lib/ga";

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    // backgroundColor: theme.palette.picker.background,
    // border: `1px solid ${theme.palette.picker.border}`,
    // borderRadius: 8,
    zIndex: 2,
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  button: {
    padding: "3px 12px",
    background: "transparent",
    // boxShadow: " 0px 4px 16px rgba(0, 0, 0, 0.08)",
    borderRadius: 6,
    cursor: "pointer",
    border: "1px solid transparent",
    "& p": {
      color: theme.palette.picker.text,
    },
    "&:hover": {
      background: theme.palette.picker.backgroundHover,
    },
    [theme.breakpoints.down("md")]: {
      "&:not(._buttonDd)": {
        margin: "0 4px",
        paddingLeft: 0,
        paddingRight: 0,
        width: "33.3%",
        justifyContent: "center",
      },
    },
  },
  selected: {
    background: `${theme.palette.picker.background} !important`,
    border: `1px solid ${theme.palette.picker.border} !important`,
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
    "& p": {
      color: "#FFF !important",
    },
    "& path": {
      fill: "#FFF !important",
    },
    "&:hover": {
      background: `${theme.palette.picker.backgroundSelectedHover} !important`,
    },
  },
  img: {
    height: 24,
    "& path": {
      fill: theme.palette.picker.text,
    },
    [theme.breakpoints.only("xs")]: {
      marginRight: 0,
      "& + p": {
        display: "none",
      },
    },
  },
}));

export interface IValue {
  value: string;
  title: string;
  slug: string;
}

export interface IProps {
  values: IValue[];
  value: IValue;
  setValue: (IValue) => void;
  minWidth?: number;
  withoutText?: boolean;
  useDropdown?: boolean;
  gaEventCategory?: string;
}

export default function Picker({
  values,
  value,
  setValue,
  withoutText,
  useDropdown = false,
  gaEventCategory,
}: IProps) {
  const classes = useStyles();
  const coinValues = {
    usd: <DollarIcon />,
    btc: <BitcoinIcon />,
    eth: <EthereumIcon />,
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {useDropdown ? (
        <Box>
          <ButtonBase
            focusRipple
            className={cx(classes.button, classes.selected, "_buttonDd")}
            aria-controls="picker-menu"
            aria-haspopup="true"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              e.stopPropagation();
            }}>
            <Typography variant="body1" noWrap>
              {Object.keys(coinValues).some((val) => val === value.slug) && (
                <Box mr="4px" key={value.slug} className={classes.img}>
                  {coinValues[value.slug]}
                </Box>
              )}
              {!withoutText && value.title}
            </Typography>
            <ExpandMore />
          </ButtonBase>
          <Menu
            id="picker-menu"
            style={{
              zIndex: 9992,
            }}
            onClick={(e) => e.stopPropagation()}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}>
            {values.map((v) => (
              <MenuItem
                key={v.value}
                onClick={(e) => {
                  setValue(v);
                  handleMenuClose();
                  e.stopPropagation();
                  if (gaEventCategory) {
                    trackEvent(
                      {
                        action: v.title,
                        category: gaEventCategory,
                        label: v.title,
                      },
                      isMobile,
                    );
                  }
                }}>
                {Object.keys(coinValues).some((val) => val === v.slug) && (
                  <Box mr="4px" key={v.slug} className={classes.img}>
                    {coinValues[v.slug]}
                  </Box>
                )}
                {!withoutText && (
                  <Typography variant="body1">{v.title}</Typography>
                )}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      ) : (
        <Box p="4px" display="flex" className={`${classes.root} pickerBox`}>
          {values.map((v) => (
            <Box
              key={v.value}
              onClick={() => {
                setValue(v);
                if (gaEventCategory) {
                  trackEvent(
                    {
                      action: v.title,
                      category: gaEventCategory,
                      label: v.title,
                    },
                    isMobile,
                  );
                }
              }}
              display="flex"
              className={`${classes.button} ${
                value.slug === v.slug ? classes.selected : ""
              }`}>
              {Object.keys(coinValues).some((val) => val === v.slug) && (
                <Box
                  mr={withoutText ? 0 : 4}
                  key={v.slug}
                  className={classes.img}>
                  {coinValues[v.slug]}
                </Box>
              )}
              {!withoutText && (
                <Typography variant="body1">{v.title}</Typography>
              )}
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}
